.progress-circle {
  width: 200px;
  height: 200px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #005246;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.percentage {
  font-size: 0.5em;
  text-anchor: middle;
  fill: #333;
}

.label-text {
  font-size: 0.31em;
  text-anchor: middle;
}
