.custom-blockquote {
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  color: #555;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.editor-container .public-DraftStyleDefault-pre {
  background: #000;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 5px;
  display: block;
  white-space: pre-wrap;
}

.font-dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  background-color: white;
}

.submit-button {
  cursor: pointer;
  margin-right: 10px;
}
