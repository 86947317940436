@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700;1,900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,600&display=swap');

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('../public/Fonts/pp-neue-montreal-cufonfonts/ppneuemontreal-book.otf')
    format('opentype');
}

@font-face {
  font-family: 'PP Neue Montreal SemiBold';
  src: url('../public/Fonts/pp-neue-montreal-cufonfonts/ppneuemontreal-medium.otf')
    format('opentype');
}

/* @font-face {
  font-family: 'TIMMONSNY-Regular';
  src: url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.eot');
  src: url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.eot?#iefix')
      format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.woff2')
      format('woff2'),
    url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.woff')
      format('woff'),
    url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.ttf')
      format('truetype'),
    url('https://db.onlinewebfonts.com/t/26b4978e67a51484ed7bccea1ffb38b8.svg#TIMMONSNY-Regular')
      format('svg');
} */

@font-face {
  font-family: 'TIMMONSNY-Regular';
  src: url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot');
  src: url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.eot?#iefix')
      format('embedded-opentype'),
    url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff2')
      format('woff2'),
    url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.woff')
      format('woff'),
    url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.ttf')
      format('truetype'),
    url('https://db.onlinewebfonts.com/t/bf9f5d50c1b928ff21436517a1a95ad9.svg#Proxima Nova')
      format('svg');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* all Fonts classes */

.poppins {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.lato-regular {
  font-family: 'Lato', sans-serif;
  font-style: normal;
}

.timmonsny-regular {
  font-family: 'TIMMONSNY-Regular';
  font-style: normal;
}

.pp-regular {
  font-family: 'PP Neue Montreal';
}

.pp-medium {
  font-family: 'PP Neue Montreal SemiBold';
}

.font-family-default {
  font-family: 'sans-serif';
}
.font-family-Arial {
  font-family: Arial, sans-serif;
}
.font-family-Georgia {
  font-family: Georgia, serif;
}
.font-family-Courier {
  font-family: 'Courier New', Courier, monospace;
}
.font-family-Times {
  font-family: 'Times New Roman', Times, serif;
}

/* main css starts here */
body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  /* font-family: cursive; */
}

.glass {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
