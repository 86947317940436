.slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.slide-active {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide-next {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-next .slide-next-img {
  width: 250px;
  height: 350px;
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
}

.slider-content {
  position: absolute;
  bottom: 5%;
  width: 90%;
  height: 100px;
  overflow: hidden;
  z-index: 10;
}

.slider-content-active {
  position: absolute;
  top: 0;
  left: 100px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.slider-content-next {
  position: absolute;
  top: 200px;
  left: 60px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

h1 {
  text-transform: uppercase;
  font-family: 'TIMMONSNY-Regular';
  font-size: 60px;
  font-weight: bold;
  color: #fff;
}

h1 span {
  position: relative;
}

@media (max-width: 900px) {
  .links,
  .shop,
  footer {
    display: none;
  }
  .logo {
    justify-content: center;
    text-align: center;
  }
}
